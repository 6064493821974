<template>
  <v-container fill-height class="d-flex justify-center align-center">
    <v-row justify="center">
      <div class="bt-login pa-10">
        <v-form autocomplete="off">
          <v-row>
            <v-col xs="12" cols="12">
              <img src="/img/icons/logo.png" class="bt-login-image mx-auto d-flex" />
            </v-col>
            <v-col xs="12" cols="12" v-show="showInputUsername">
              <v-card elevation="3" class="pa-3 rounded-xl">
                <v-text-field
                  color="black"
                  placeholder="Masukkan NIK Anda"
                  v-model="form.data.nama_user"
                  autocomplete="false"
                  hide-details
                  flat
                  solo
                  dense
                  prepend-inner-icon="mdi-account"
                  name="nama_user"
                  :type="'number'"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </v-card>
            </v-col>
            <v-col xs="12" cols="12" v-show="showInputUsername">
              <v-btn block :style="{ backgroundColor: 'var(--primary)' }" class="white--text rounded-pill py-8 text-h6" type="button" @click="checkUsername" :loading="form.loading">
                <template v-slot:loader>
                  <span class="custom-loader" :loading="form.loading"> Mohon tunggu... </span>
                </template>
                Cek NIK
              </v-btn>
            </v-col>
            <v-col xs="12" cols="12" v-show="showInputPassword">
              <v-card elevation="3" class="pa-3 rounded-xl">
                <v-text-field
                  color="black"
                  placeholder="Masukkan Password Anda"
                  v-model="form.data.password"
                  :type="showPass ? 'text' : 'password'"
                  autocomplete="false"
                  hide-details
                  flat
                  solo
                  dense
                  prepend-inner-icon="mdi-lock"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                />
              </v-card>
            </v-col>
            <v-col xs="12" cols="12">
              <v-btn block :style="{ backgroundColor: 'var(--primary)' }" class="white--text white--text rounded-pill py-8 text-h6" type="button" @click="doLogin" v-show="showInputPassword">
                Login
                <template v-slot:loader>
                  <span class="custom-loader" :loading="form.loading"> Mohon tunggu... </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-snackbar v-model="alert.show" :timeout="5000">
          {{ alert.msg }}
        </v-snackbar>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Toast from "@/components/Toast";
import { mapGetters, mapActions } from "vuex";
import services from "../services";
import { saveData, deleteData } from "@/utils/indexedDB";
export default {
  name: "Login",
  components: {
    Toast,
  },
  data() {
    return {
      form: {
        data: {
          nama_user: "",
          password: "",
          fa_code: "",
          status: 0,
        },
        loading: false,
      },
      showPass: false,
      alert: {
        show: false,
        msg: "",
      },
      showInputUsername: true,
      showInputPassword: false,
    };
  },
  methods: {
    ...mapActions(["login", "setUserRole"]),

    onlyNumber(evt) {
      const { target, key } = evt;
      const expect = target.value.toString() + key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      }
    },

    async doLogin() {
      this.form.loading = true;
      try {
        let payload = new FormData();
        payload.append("username", this.form.data.nama_user);
        payload.append("fa_code", this.form.data.fa_code);
        payload.append("password", this.form.data.password);
        payload.append("status", this.form.data.status);

        // Request pertama: Login
        const req = await services.authCheckPassworLogin(payload);
        const { status, msg, data } = req.data;
        if (status === false) {
          this.alert = { show: true, msg: msg };
          return;
        }

        const { token } = data;
        data.token = token;
        this.login(data);

        await saveData("user_data", data, "user");

        try {
          const roleReq = await services.authCheckUserRole(payload);
          this.setUserRole(roleReq.data.data);
        } catch (error) {
          this.alert = { show: true, msg: "Terjadi kesalahan,Silahkan Hubungi IT." };
        }

        this.alert = { show: true, msg: msg };
        this.$router.push("/").then(() => {
          this.$root.$emit("userUpdated");
        });
      } catch (error) {
        this.alert = { show: true, msg: "Terjadi kesalahan, silakan coba lagi." };
      } finally {
        this.form.loading = false;
      }
    },

    async checkUsername() {
      this.form.loading = true;
      try {
        const payload = new FormData();
        payload.append("username", this.form.data.nama_user);
        const req = await services.authCheckUsername(payload);
        const { status, msg, data } = req.data;
        if (status === true) {
          this.form.data.fa_code = data.fa_code || "";
          this.form.data.status = data.status || 0;
          this.showInputPassword = true;
          this.showInputUsername = false;
        } else {
          this.alert = {
            show: true,
            msg: msg,
          };
          this.form.loading = false;
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
        this.form.loading = false;
      }
    },
  },
};
</script>
